import React from 'react';
import Aside from '../../components/Aside'
import Header from '../../components/Header'

import { FaChevronRight } from 'react-icons/fa'

import AccountImg from '../../assets/account.svg'
import PhotoImg from '../../assets/photo.svg'

import { Container, Inside, FormContainer, NavigateLine, FormNew } from './style'

const NewSubscription = () => {
	return (
		<Container>
			<Aside />
			<Inside>
				<Header title="Nova Subscrição" />
				<FormContainer>
					<NavigateLine>
						<div className="navigator">
							<img src={AccountImg} />
							<p>1. Detalhes da Subscrição</p>
						</div>
						<FaChevronRight className="arrow" color="#CCCB00" size={18} />
						<div className="navigator">
							<img src={PhotoImg} />
							<p>2. Fotos</p>
						</div>
					</NavigateLine>
					<FormNew>
						<form>
							<div>
								<p>Proposta</p>
								<select>
									<option default>Corretor</option>
								</select>
							</div>

							<div>
								<p>Segurado</p>
								<div className="flexInput">
									<input placeholder="Nome do Segurado" />
									<input placeholder="CPF" />
								</div>
							</div>

							<div>
								<p>Sobre a Bike</p>
								<div className="flexInput">
									<select>
										<option default>Marca</option>
									</select>

									<select>
										<option default>Modelo</option>
									</select>

									<input placeholder="Valor da Bike" />
								</div>

								<div className="flexInput">
									<input placeholder="Ano" />
									<input placeholder="Nº de Série" />
								</div>
							</div>
						<button>Próximo</button>
						</form>
					</FormNew>
				</FormContainer>
			</Inside>
		</Container>
	);
}

export default NewSubscription;
