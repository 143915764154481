import React from 'react'

import Aside from '../../components/Aside'
import Header from '../../components/Header'
import { Container, TableContainer, Table } from './style'

const Subscription = () => {

	return(
		<Container>			
			<Aside/>
			<div className="inside">
			<Header title="Subscrição"/>
			<TableContainer>
				<form>
					<div>
						<select>
							<option default>Status</option>
						</select>

						<select>
							<option default>Marca</option>
						</select>

						<input placeholder="Nº Subscrição"></input>

						<input placeholder="Data"></input>

						<input placeholder="CPF"></input>

						<input placeholder="Nº Série Bike"></input>
						
						<input placeholder="Nome do Segurado"></input>

						<input placeholder="Corretor"></input>
						<button>Consultar</button>
					</div>							
					</form>

					<Table>
					<tr>
						<th>Data</th>
						<th>Nº Subscrição</th>
						<th>Corretor</th>
						<th>Nome do Segurado</th>
						<th>CPF</th>
						<th>Marca</th>
						<th>Modelo</th>
						<th>Ano</th>
						<th>Nº Série Bike</th>
						<th>Valor da Bike</th>
						<th>Status</th>						
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					<tr>
						<td>08/02/2020</td>
						<td>203145</td>
						<td>Corretor</td>
						<td>Jose Reinaldo Pereira Silva</td>
						<td>111.222.333-44</td>
						<td>Marca</td>
						<td>Modelo</td>
						<td>2020</td>
						<td>09659438877</td>
						<td>R$ 3.500,00</td>
						<td>Em análise</td>
					</tr>
					</Table>
			</TableContainer>
			</div>
		</Container>

	);
}

export default Subscription