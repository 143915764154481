import styled from 'styled-components';

export const LoginContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	background-color: #383838;
`

export const FormContainer = styled.div`
	position: relative;
	margin: 0 auto;
	top: 100px;
	width: 300px;
	height: 400px;
`
export const InputField =  styled.div`
	margin-top: 20px;
	input{
		width: 100%;
		height: 40px;
		color: #333;
		border: 1px solid #dcdce6;
		border-radius: 8px;
		padding: 0 24px;
		margin-bottom: 10px;
		background: #565656;
		border-color: #fff;
		color: #fff;
	}
	input::placeholder { 
		color: #fff; 
	}
`
export const Img = styled.img`
	width: 200px;
	justify-content: center;
	display: flex;
	margin: 0 auto;
`

export const Button = styled.button`
	display: flex;
	text-align: center;
	position: relative;
	justify-content: center;
	margin: 0 auto;
	width: 80%;
	height: 40px;
	background: #d7db2d;
	border: 0;
	border-radius: 8px;
	color: #565656;
	margin-top: 16px;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	transition: filter 0.2s;

	:hover{
	filter:brightness(90%)
	}
`




