import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	background: #F9F9FC;
`
export const Inside = styled.div`
	width: 100%;
	height: 10vh;
	flex-direction: column;
`
export const FormContainer = styled.div`
	height: auto;
	margin: 50px 20px 0px 20px;	
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 3px 9px #0000001F;
	border-radius: 12px;
	opacity: 1;
`
export const NavigateLine = styled.div`
	display: flex;
	padding: 10px 20px 20px 0;
	border-bottom: 1px solid #e0e0e0;
	.arrow{
		position: relative;
		top: 50px;
	}
	.navigator{
		padding: 20px 0px 10px 0px;
		width: 300px;
		p{
			text-align: center;
			font-size: 15px;
			color: #CCCB00;
			margin-top: 10px;
		}	
	}
	img{
		display: flex;
		justify-content: center;
		margin: 0 auto;
		width: 60px;
	}
`
export const FormNew = styled.div`
	form{
		padding-bottom: 20px;
	}
	p{
		margin-left: 20px;
		margin-top: 30px
	}
	.flexInput{
		display: flex;
		margin-right: 20px;
	}

	input, select{
		background: #FFFFFF 0% 0% no-repeat padding-box;
		border: 1px solid #A5A5A5;
		opacity: 1;
		width: 250px;
		height: 30px;
		margin: 20px;
		padding-left: 5px;
	}

	button{
		background: #CCCB00 0% 0% no-repeat padding-box;
		border-radius: 5px;
		opacity: 1;
		font-size: 16px;
		width: 150px;
		height: 35px;
		margin-left: 20px;
		
	}
`