import React from 'react';
import logoAside from '../../assets/logoAside.png'
import { FaWrench, FaCaretDown } from "react-icons/fa";

import { AsideContainer, AsideList, AsideItem, Menu } from './style';

const Aside = () => {
	return(
		<AsideContainer>
			<img src={logoAside} width="300px"/>				
					<Menu>
						<FaWrench  color="#d7db2d" size={18}/>
						&nbsp;&nbsp;<span>Subscrição</span>&nbsp;
						<FaCaretDown color="#d7db2d" size={18} />
					</Menu>
					<AsideList>
						<AsideItem><a href="/subscription">Consultar</a></AsideItem>
						<AsideItem><a href="/new/subscription">Nova Subscrição</a></AsideItem>
						<AsideItem><a href="/reports">Relatório</a></AsideItem>
				</AsideList>
		</AsideContainer>
	);
}
export default Aside;