import React, { Fragment } from 'react'

import {HeaderContainer, HeaderTop, HeaderList, HeaderBottom} from './style'
import { FaSignOutAlt, FaCaretDown, FaUserAlt, FaRegEnvelope, FaBell, FaCog, FaSearch } from 'react-icons/fa'

const Header = (props) => {
	return(
  	<HeaderContainer>
			<HeaderTop>
				<HeaderList>
					<ul>
						<li className="icons"><FaSearch size={24} color="#CCCB00"/></li>
						<li className="icons"><FaCog size={24} color="#CCCB00"/></li>
						<li className="icons"><FaBell size={24} color="#CCCB00"/></li>
						<li className="icons"><FaRegEnvelope size={24} color="#CCCB00"/> </li>
						<li className="userList">
							<FaUserAlt size={18} color="#CCCB00"/> 
								&nbsp;&nbsp;Olá, Cristiano Monteiro
							<FaCaretDown size={18} color="#CCCB00"/> 
						</li>
						<li className="userList">
							Sair&nbsp; 
							<FaSignOutAlt size={18} color="#CCCB00"/> 
						</li>
					</ul>
				</HeaderList>
			</HeaderTop>
			<HeaderBottom>
					<h3>{props.title}</h3>
					<HeaderList>
						<ul>
							<li className="dataFilter">Dia</li>
							<li className="dataFilter">Mês</li>
							<li className="dataFilter">Ano</li>
						</ul>
					</HeaderList>
			</HeaderBottom>
		</HeaderContainer>
	);
}

export default Header;