import React, { Profiler } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Logon from './pages/Logon'
import Subscription from './pages/Subscription'
import NewSubscription from './pages/NewSubscription'   
import Reports from './pages/Reports'

export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Logon}/>
                <Route path="/subscription" component={Subscription}/>
                <Route path="/new/subscription" component={NewSubscription}/>
                <Route path="/reports" component={Reports}/>
            </Switch>
        </BrowserRouter>
    )
}