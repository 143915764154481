import styled from 'styled-components';

export const AsideContainer = styled.aside`
	width: 300px;
	height: 130vh;
	background: #383838;
	padding-top: 30px;		
`
export const AsideList = styled.ul`
	a{
		color: #fff;
		font-size: 18px;
		position: relative;
		left: 73px;
		text-decoration: none;
	}
`
export const AsideItem = styled.li`
	margin-bottom: 10px
`
export const Menu = styled.div`
	margin-top: 70px;
	position: relative;
	display: flex;
	left: 45px;
	color: #d7db2d;
	font-size: 18px;
	margin-bottom: 10px;

	svg{
		position: relative;
		top: 5px;
	}
`