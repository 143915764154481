import styled from 'styled-components';

export const HeaderContainer = styled.header`
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
	background: #fff
`
export const HeaderTop = styled.div`
	display: flex;
	height: 101px;
	border-bottom: 1px solid #e0e0e0;
	color: #535353;
`
export const HeaderList = styled.div`
	display: flex;
	position: sticky;
	left: 100%;
	margin-right: 20px;
	
	.icons{
		margin-top: 35px;
		margin-left: 30px
	}

	ul{
		display: flex;

		.userList{
			margin: 35px;

			svg{
				position: relative;
				top: 3px
			}

		}
	}

	.dataFilter{
		margin: 20px 20px 0px 20px;
		padding-top: 4px;
		background: #F9F9FC 0% 0% no-repeat padding-box;
		border: 1px solid #B4B4B4;
		border-radius: 3px;
		opacity: 1;
		width: 100px;
		height: 36px;
		color: #CCCB00;		
		text-align: center;
		transition: hover 0.2;
	}
	.dataFilter:hover{
		background: #DDDC22;
		color: #383838;
	}
`
export const HeaderBottom = styled.div`
	display: flex;
	height: 99px;
	padding-top: 10px;
	h3{
		font-size: 26px;
		font-weight: 500;
		padding: 20px 10px 0px 40px;
		color: #343434;
	}
`