import React from 'react';

import logoImg from '../../assets/logo.png'

import {LoginContainer, FormContainer, InputField, Img, Button} from './style'

const Logon = () => {	
	return(
		<LoginContainer>
			<FormContainer>
				<Img src={logoImg} />
				<form>
				<InputField>									
				<input type="text" placeholder="E-mail" id="name" required />
    			<input type="password" placeholder="Senha" />
				</InputField>	
				<Button>Entrar</Button>			
				</form>
			</FormContainer>
		</LoginContainer>
	);
};

export default Logon;